
<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-card title="예비품 기본정보" class="cardClassDetailInfo" topClass="topcolor-orange">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveInfo" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-3">
            <c-plant
              :required="true"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="spareParts.plantCd" />
          </div>
          <div class="col-3">
            <c-text
              :editable="editable"
              label="예비품명"
              :required="true"
              name="spareNm"
              v-model="spareParts.spareNm">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              :required="true"
              :editable="editable"
              label="용도"
              name="purpose"
              v-model="spareParts.purpose">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              :editable="editable"
              label="용량"
              name="volume"
              v-model="spareParts.volume">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              :required="true"
              :editable="editable"
              label="동력"
              type="number"
              v-model="spareParts.power">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              :required="true"
              :editable="editable"
              label="등록수량"
              type="number"
              name="regCount"
              v-model="spareParts.regCount">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              :required="true"
              :editable="editable"
              label="제조회사"
              name="inspCycle"
              v-model="spareParts.inspCycle">
            </c-text>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>
1
<script>
export default {
  name: 'spare-parts-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          equipSeq: 0,
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      spareParts: {
        spareSeq: 1,
        plantName: "사업장1",
        plantCd: "1",
        spareNm: "아토마이저",
        purpose: "S/D용",
        volume: "400L용",
        power: '',
        regCount: '1',
        inspCycle: "삼영화학",

      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.getList();
    },
    getList() {
    },
    reset() {
      Object.assign(this.$data.info, this.$options.data().info);
    },
    saveInfo() {
      this.$emit('saveInfoEvt');
    }
  }
};
</script>